import { ApolloClient, InMemoryCache } from '@apollo/client';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/lib/Option';
import * as TE from 'fp-ts/lib/TaskEither';

import { fetchDominoUser } from './fetchDominoUser';

import { User, UserAnalytics } from '@domain/auth';

// import { getAnalyticsIndividualQuery } from '@repositories/auth/queries';

// fetchUserAnalytics :: ApolloClient<InMemoryCache>, User -> TaskEither<Error, Option<User>>
export const fetchUserAnalytics = (
  client: ApolloClient<InMemoryCache>,
  { individualId }: User
): TE.TaskEither<unknown, O.Option<UserAnalytics>> => {
  if (individualId === null) {
    return TE.of(O.none);
  }
  // fetchDominoUser(individualId as string)
  return pipe(
    fetchDominoUser(individualId as string),
    TE.map(({ getIndividual }: UserAnalytics) => {
      return getIndividual;
    }),
    TE.map(O.some)
  );
};
