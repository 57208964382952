import * as React from 'react';
import InfogramEmbedNPM from '@rotaryintl/app-infogram-embed';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const InfogramEmbed = (props) => {
  return (
      <InfogramEmbedNPM title={props.fields?.Title?.value} infogramID={props.fields?.InfogramID?.value} />
  );
};

export default withSitecoreContext()(InfogramEmbed); 