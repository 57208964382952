import Workflow from './base';

import { DomuiError, DynamicData, RecordList } from '@domui-domain/type';

class Individual extends Workflow {
  constructor() {
    super('individualwf');
  }

  async getDominoIdUsingDisId(disId: string) {
    this.setAction('getDominoId');
    this.setData({
      idType: 'DISID',
      value: disId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes?.dominoId || res?.data?.dominoId || undefined;
    } catch (error) {
      throw new Error('Failed to fetch domino id');
    }
  }

  async getDominoId(nfKey: string) {
    this.setAction('getDominoId');
    this.setData({
      idType: 'NFKey',
      value: nfKey,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes?.dominoId || res?.data?.dominoId || undefined;
    } catch (error) {
      throw new Error('Failed to fetch domino id');
    }
  }

  async getDominoIndividual(dominoId: string) {
    this.setAction('getIndividual');
    this.setData({
      id: dominoId,
    });
    try {
      const res = await this.getCall();

      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch individual info');
    }
  }

  async getPreferredLanguage(profileId: string) {
    this.setAction('getPreferredLanguage');
    this.setData({
      profileId,
    });
    try {
      const res = await this.getCall();

      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch individual info');
    }
  }

  async getIndividualPrograms(id: string) {
    this.setAction('getIndividualPrograms');
    this.setData({ id });
    try {
      const res = await this.getCall();

      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch individual info');
    }
  }

  async getProgramList() {
    this.setAction('getProgramList');
    this.setData({});
    try {
      const res = await this.getCall();

      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch Program list info');
    }
  }

  async getContactInfo(dominoId: string) {
    this.setAction('getContactInfo');
    this.setData({
      dominoId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch contact info');
    }
  }

  async updateContactInfo(dominoId: string, data: DynamicData) {
    this.setAction('updateContactInfo');
    this.setData({
      dominoId,
      putData: data,
    });
    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async getProfileInfo(dominoId: string) {
    this.setAction('getProfileInfo');
    this.setData({
      dominoId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get info');
    }
  }

  async updateProfileInfo(dominoId: string, data: DynamicData) {
    this.setAction('updateProfileInfo');
    this.setData({
      dominoId,
      putData: data,
    });
    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async getProfessionalExperience(dominoId: string) {
    this.setAction('getProfessionalExperience');
    this.setData({
      dominoId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get professional info');
    }
  }

  async updateProfessionalExperience(
    dominoId: string,
    sharingPermissionId: string,
    data: DynamicData
  ) {
    this.setAction('updateProfessionalExperience');
    this.setData({
      dominoId,
      sharingPermissionId,
      putData: data,
    });
    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async updateEditBackground(data: DynamicData) {
    this.setAction('updateEditBackground');
    this.setData({
      putData: data,
    });
    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async getAreasofExpertise(dominoId: string) {
    this.setAction('getAreasOfExpertise');
    this.setData({
      dominoId,
    });

    const res = await this.getCall();
    return res?.data?.wfRes || res?.data || undefined;
  }

  async updateAreasOfExpertise(
    dominoId: string,
    sharingPermissionId: string,
    data: DynamicData
  ) {
    this.setAction('updateAreasOfExpertise');
    this.setData({
      dominoId,
      sharingPermissionId,
      putData: data,
    });

    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async UpdateProgramsSharingPermissions(data: DynamicData) {
    this.setAction('updateProgramsSharingPermissions');
    this.setData({
      putData: data,
    });

    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async createProgram(
    individualId: string,
    programId: string,
    fields: RecordList
  ) {
    this.setAction('createProgram');
    this.setData({
      individualId,
      programId,
      fields,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to create individual Program');
    }
  }

  async updateProgram(
    individualId: string,
    programId: string,
    individualProgramId: string,
    fields: RecordList
  ) {
    this.setAction('updateProgram');
    this.setData({
      individualId,
      programId,
      individualProgramId,
      fields,
    });
    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async deleteProgram(individualProgramId: string) {
    this.setAction('deleteProgram');
    this.setData({ individualProgramId });
    try {
      const res = await this.deleteCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to delete individual Program');
    }
  }

  async fetchPossibleRoles(dominoId: string) {
    this.setAction('fetchPossibleRoles');
    this.setData({
      id: dominoId,
    });

    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch possible roles info');
    }
  }

  async updateIndividual(domId: string, data: DynamicData) {
    this.setAction('updateIndividual');
    this.setData({
      id: domId,
      putData: data,
    });

    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to update individual');
    }
  }

  async createIndividual(data: DynamicData) {
    this.setAction('createIndividual');
    this.setData({
      postData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to create individual');
    }
  }

  async updateProfilePhoto(dominoId: string, requestBody: DynamicData) {
    this.setAction('updateProfilePhoto');
    this.setData({
      dominoId,
      putData: requestBody,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async deleteProfilePhoto(dominoId: string) {
    this.setAction('deleteProfilePhoto');
    this.setData({ dominoId });
    try {
      const res = await this.deleteCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to delete profile photo.');
    }
  }

  async setPreferredLanguage(dominoId: string, data: DynamicData) {
    this.setAction('setPreferredLanguage');
    this.setData({
      dominoId,
      putData: data,
    });
    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }
}

export default Individual;
