import { L2Hero as L2HeroNPM } from '@rotaryintl/component-landing-pages';
import { RichText, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

const L2Hero = ({ fields: { Title, Description, ImageDesktop, ImageMobile } = {} }) => (
  <L2HeroNPM
    title={<Text field={Title} />}
    description={<RichText field={Description} />}
    imageDesktop={ImageDesktop?.value?.src}
    imageMobile={ImageMobile?.value?.src}
    imageAlt={ImageDesktop?.value?.alt}
  />
);

export default withSitecoreContext()(L2Hero);
