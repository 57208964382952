import { ApolloClient, InMemoryCache } from '@apollo/client';
import { pipe } from 'fp-ts/function';
import * as T from 'fp-ts/lib/Task';

import { AppConfig, FullPipe, SecondLevelPipe } from '@domain/appConfig';
import { Lang } from '@domain/language-negotiation';

import { getMaintenanceMode } from '@repositories/maintenance-mode/get-maintenance-mode';

import { fetchUserAndAnalyticsPipe } from '@domui-repositories/auth/pipelines';
import { negotiateLanguagePipe } from '@domui-repositories/language-negotiation/pipelines';

export const getAppConfig = (
  client: ApolloClient<InMemoryCache>,
  currentLanguage: Lang,
  pageContextLanguage: Lang,
  delocalizedPath: string
): T.Task<AppConfig> =>
  pipe(
    T.bindTo('maintenanceMode')(getMaintenanceMode),
    T.bind('auth', () => fetchUserAndAnalyticsPipe(client)),
    T.bind('negotiatedLang', ({ auth: { user } }: SecondLevelPipe) =>
      negotiateLanguagePipe(user, client, currentLanguage, pageContextLanguage)
    ),
    T.map((data: FullPipe) => {
      const {
        auth: { user, analytics: userAnalytics },
        maintenanceMode: { maintenanace: isMaintenanceMode = 0 },
        negotiatedLang: { langCode: language, localizedPath },
      } = data;
      return {
        delocalizedPath,
        user,
        userAnalytics,
        isMaintenanceMode: !!isMaintenanceMode,
        language,
        localizedPath,
      };
    })
  );
