/* eslint-disable @typescript-eslint/no-explicit-any */
export type DynamicData = Record<string, any> | null | undefined;
export type RecordList = { [x: string]: any };
export type DomuiError =
  | ({
      statusCode?: string;
      message?: string;
      description?: string;
    } & object)
  | undefined
  | null;

export enum LogLevel {
  Debug = 'debug',
  Error = 'error',
  Info = 'info',
  Warn = 'warn',
}

export enum ClubType {
  RotaractClub = 'Rotaract Club',
  RotaractSatelliteClub = 'Rotaract Satellite Club',
  RotaryClub = 'Rotary Club',
  SatelliteClub = 'Satellite Club',
}

export enum ClubTypeFilterValue {
  RotaractClub = 'Rotaract Club',
  RotaractSatelliteClub = 'Rotaract Satellite Club',
  RotaryClub = 'Rotary Club',
  SatelliteClub = 'Satellite Club',
}

export enum FieldCharSet {
  OnlyDigits = 'OnlyDigits',
  OnlyLatin = 'OnlyLatin',
}

export enum FieldType {
  Date = 'Date',
  Number = 'Number',
  Options = 'Options',
  String = 'String',
}

export enum WeekdayShort {
  Friday = 'Fri',
  Monday = 'Mon',
  Saturday = 'Sat',
  Sunday = 'Sun',
  Thursday = 'Thu',
  Tuesday = 'Tue',
  Wednesday = 'Wed',
}

export enum WeekdayLong {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Saturday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
}
