import Workflow from './base';

import { DomuiError, DynamicData } from '@domui-domain/type';

class Member extends Workflow {
  constructor() {
    super('memberwf');
  }

  async GetAffiliationAndClubsInfo(individualId: string) {
    this.setAction('GetAffiliationAndClubsInfo');
    this.setData({
      individualId,
    });

    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get club and district  info');
    }
  }

  async ClubMembersSearch(
    pageNumber: number,
    pageSize: number,
    data: DynamicData
  ) {
    this.setAction('clubMembersSearch');
    this.setData({
      pageNumber,
      pageSize,
      searchAndFilterData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get club member search  info');
    }
  }

  async DistrictMembersSearch(
    pageNumber: number,
    pageSize: number,
    data: DynamicData
  ) {
    this.setAction('districtMembersSearch');
    this.setData({
      pageNumber,
      pageSize,
      searchAndFilterData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get district member search  info');
    }
  }

  async DistrictClubsSearch(
    pageNumber: number,
    pageSize: number,
    data: DynamicData
  ) {
    this.setAction('districtClubsSearch');
    this.setData({
      pageNumber,
      pageSize,
      searchAndFilterData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async AddMembersSearch(
    pageNumber: number,
    pageSize: number,
    data: DynamicData
  ) {
    this.setAction('addMembersSearch');
    this.setData({
      pageNumber,
      pageSize,
      searchMemberData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async FetchExistingEmail(data: DynamicData) {
    this.setAction('fetchExistingEmail');
    this.setData({
      putData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch existing mail info.');
    }
  }

  async fetchPossibleSponsors(data: DynamicData) {
    this.setAction('fetchPossibleSponsors');
    this.setData({
      putData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch possible sponsors info.');
    }
  }

  async AddMemberToClub(data: DynamicData) {
    this.setAction('addMemberToClub');
    this.setData({
      putData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async RemoveFromAssignedRole(data: DynamicData) {
    this.setAction('removeFromAssignedRole');
    this.setData({
      requestData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async FetchPreviousOfficers(
    pageNumber: number,
    pageSize: number,
    data: DynamicData
  ) {
    this.setAction('fetchPreviousOfficers');
    this.setData({
      pageNumber,
      pageSize,
      putData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async AssignRoleToMember(data: DynamicData) {
    this.setAction('assignRoleToMember');
    this.setData({
      requestData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async MembershipActions(orgId: string, individualId: string) {
    this.setAction('membershipActions');
    this.setData({
      orgId,
      individualId,
    });

    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get membership data.');
    }
  }

  async EditClubMembership(data: DynamicData) {
    this.setAction('editClubMembership');
    this.setData({
      requestData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async ReplaceOfficer(data: DynamicData) {
    this.setAction('replaceOfficer');
    this.setData({
      requestData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async GetTerminationReason() {
    this.setAction('getTerminationReason');
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get termination reason data.');
    }
  }

  async TerminateClubMembership(data: DynamicData) {
    this.setAction('terminateClubMembership');
    this.setData({
      requestData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async AllActiveClubsByDistrictAndRotaryYear(data: DynamicData) {
    this.setAction('allActiveClubsByDistrictAndRotaryYear');
    this.setData({
      requestData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async AssistantDistrictGovernorsWithAssignedClubs(data: DynamicData) {
    this.setAction('assistantDistrictGovernorsWithAssignedClubs');
    this.setData({
      requestData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async PrevYearAssistantDistrictGovernorsWithAssignedClubs(data: DynamicData) {
    this.setAction('prevYearAssistantDistrictGovernorsWithAssignedClubs');
    this.setData({
      requestData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error(
        'Failed to fetch assistant district governors assigned clubs info.'
      );
    }
  }

  async findMemberSearch(
    pageNumber: number,
    pageSize: number,
    data: DynamicData
  ) {
    this.setAction('findMemberSearch');
    this.setData({
      pageNumber,
      pageSize,
      putData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get  member search  info');
    }
  }

  async UpdateClubAssignmentsForAssistantGovernors(requestData: DynamicData) {
    this.setAction('updateClubAssignmentsForAssistantGovernors');
    this.setData({
      requestData,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }

  async AddMemberToClubInfo(individualId: string) {
    this.setAction('addMemberToClubInfo');
    this.setData({
      individualId,
    });

    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as DomuiError;
      throw typedError;
    }
  }
}

export default Member;
