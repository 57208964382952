import { pipe } from 'fp-ts/function';
import * as E from 'fp-ts/lib/Either';
import * as TE from 'fp-ts/lib/TaskEither';

import { UserAnalytics } from '@domain/auth';

import { getStorageData } from '@domui-utils/storage';
import Individual from '@domui-utils/workflow/individual';

export const fetchPreferredLangs = (
  profileId: string | null
): UserAnalytics => {
  const wfService = new Individual();

  const userCacheId = `domino-user-account-${profileId}`;
  const userCachedInfo = getStorageData(userCacheId);
  if (userCachedInfo) {
    return pipe(
      TE.tryCatch(async () => {
        if (userCachedInfo?.data?.perfLang?.shortCode) {
          return userCachedInfo?.data?.perfLang?.shortCode;
        }
        return null;
      }, E.toError)
    );
  }

  return pipe(
    TE.tryCatch(async () => {
      if (profileId) {
        const perfLang = await wfService.getPreferredLanguage(
          profileId as string
        );
        return perfLang?.shortCode;
      }
      return null;
    }, E.toError)
  );
};
