import { pipe } from 'fp-ts/function';
import * as E from 'fp-ts/lib/Either';
import * as TE from 'fp-ts/lib/TaskEither';

import { UserAnalytics } from '@domain/auth';

import { getStorageData } from '@domui-utils/storage';
import Individual from '@domui-utils/workflow/individual';

export const fetchDominoUser = (individualId: string | null): UserAnalytics => {
  const wfService = new Individual();

  const userCacheId = `domino-user-account-${individualId}`;
  const userCachedInfo = getStorageData(userCacheId);
  if (userCachedInfo) {
    return pipe(
      TE.tryCatch(async () => {
        return {
          data: {
            getIndividual: {
              yearOfBirth: userCachedInfo?.data?.individualInfo?.yearOfBirth,
            },
          },
        };
      }, E.toError)
    );
  }
  return pipe(
    TE.tryCatch(async () => {
      const domId = await wfService.getDominoIdUsingDisId(
        individualId as string
      );
      const userInfo = await wfService.getDominoIndividual(domId);
      return {
        data: {
          getIndividual: {
            yearOfBirth: userInfo?.yearOfBirth,
          },
        },
      };
    }, E.toError)
  );
};
