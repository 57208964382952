import { FORM_STATUSES } from '@domain/storage';

import { getStorageID } from '@use-cases/storage';

export const getStorageData = (id: string) => {
  const storageData = sessionStorage.getItem(getStorageID(id));

  if (id && storageData) {
    const { status = FORM_STATUSES.STARTED, ...rest } = JSON.parse(storageData);
    return {
      data: rest,
      status,
    };
  }
  return null;
};
