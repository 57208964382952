/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/lib/Task';
import * as TE from 'fp-ts/lib/TaskEither';

import { defaultLanguage } from '../../../../../languages';
import { fetchPreferredLangs } from '../api/fetchPreferredLangs';

import { Lang } from '@domain/language-negotiation';

// import { lazyPreferredLangs } from '@domui-repositories/language-negotiation/queries';

// negotiateLangByIndividualId :: (client: ApolloClient<InMemoryCache>, individualId: string) -> Task<Lang>
export const negotiateLangByIndividualId = (
  client: ApolloClient<InMemoryCache>,
  individualId: string
): T.Task<Lang> => {
  // return T.of(defaultLanguage);
  return pipe(
    fetchPreferredLangs(individualId),
    TE.getOrElse(
      (): T.Task<Lang> => {
        return T.of(defaultLanguage);
      }
    )
  );
};
