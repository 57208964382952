import { useEffect, useState } from 'react';

import { User } from '@domain/auth';
import { SITECORE_USER_TYPES } from '@domain/clubs';

import { getBackendLogoutUrl, getWebappLogoutUrl } from '@repositories/auth';

import {
  FEATURE_SITECORE_HEADER,
  FEATURE_SITECORE_HOMEPAGE,
  FEATURE_SITECORE_LANDING_PAGE,
  isEnabled,
} from '@utils/features';

import { useDominoUserAccount } from '@domui-hooks/useDominoUserAccount';
import {
  DOMUI_CLUB_DISTRICT_DATA,
  SITECORE_INDIVIDUAL_TOKEN,
} from '@domui-sitecore/constants';
import { getAllDayLabel } from '@domui-sitecore/sitecoreTranslations';

import { useTranslation } from '@external/react-i18next';

export const useSiteCoreUserType = (
  user: User | undefined,
  isProcessingLogin: boolean
) => {
  const { t } = useTranslation();
  const individualId = user?.individualId || '';
  const { data, loading } = useDominoUserAccount(individualId);
  const [areClubsAvailable, setClubsAvailable] = useState(false);

  useEffect(() => {
    if (
      individualId &&
      (isEnabled(FEATURE_SITECORE_HEADER) ||
        isEnabled(FEATURE_SITECORE_HOMEPAGE) ||
        isEnabled(FEATURE_SITECORE_LANDING_PAGE))
    ) {
      setClubsAvailable(true);
    }
  }, [individualId]);

  if (
    isEnabled(FEATURE_SITECORE_HEADER) ||
    isEnabled(FEATURE_SITECORE_HOMEPAGE) ||
    isEnabled(FEATURE_SITECORE_LANDING_PAGE)
  ) {
    if (!isProcessingLogin && !user?.isLoggedIn) {
      window.localStorage.setItem(SITECORE_INDIVIDUAL_TOKEN, '');
      window.localStorage.setItem(DOMUI_CLUB_DISTRICT_DATA, '');

      return SITECORE_USER_TYPES.UNAUTHENTICATED;
    }

    if (loading) {
      window.localStorage.setItem(SITECORE_INDIVIDUAL_TOKEN, '');
      return SITECORE_USER_TYPES.USER_LOADING;
    }

    const destination = window.location.pathname;
    const newUser =
      !individualId &&
      (destination.endsWith('/account/select-member-status') ||
        destination.endsWith('/account/map-by-email') ||
        destination.endsWith('/contact'));
    const existingUser =
      areClubsAvailable && !isProcessingLogin && user?.isLoggedIn;

    let userType = SITECORE_USER_TYPES.USER_LOADING;
    if (newUser) {
      userType = SITECORE_USER_TYPES.UNAUTHENTICATED;
    } else if (existingUser) {
      // userType = getUserType(clubsAffiliation, IsDESInfoAvailable);
      // This need to be refactor
      userType = SITECORE_USER_TYPES.LEADER;
    }

    const backendLogoutUrl = getBackendLogoutUrl();
    const logOutUrl = getWebappLogoutUrl(backendLogoutUrl);
    const weekDayLabels = getAllDayLabel(t);
    const districtLabel = t(
      'search.members.filters-district-label',
      'District'
    );

    window.localStorage.setItem(
      DOMUI_CLUB_DISTRICT_DATA,
      JSON.stringify({
        individualInfo: data?.individualInfo,
        getClubDistrictData: data?.getClubDistrictData,
        districtLabel,
        weekDayLabels,
        logOutUrl,
        rotaryYear: data?.rotaryYear,
      })
    );

    return userType;
  }
  return SITECORE_USER_TYPES.UNAUTHENTICATED;
};
