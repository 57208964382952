import { ApolloClient, InMemoryCache } from '@apollo/client';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import * as T from 'fp-ts/lib/Task';
import * as TE from 'fp-ts/lib/TaskEither';

import {
  getAnalyticsUser,
  User,
  UserWithAnalytics,
  UserWithAnalyticsOptions,
} from '@domain/auth';

import { fetchUser } from '@repositories/auth/api';

import { fetchUserAnalytics } from '@domui-repositories/auth/api';

export const fetchUserAndAnalyticsPipe = (
  client: ApolloClient<InMemoryCache>
): T.Task<UserWithAnalytics> =>
  pipe(
    TE.bindTo('user')(fetchUser(client)),
    TE.bind('analytics', ({ user }) =>
      O.fold(
        () => TE.of(user),
        (user: User) => fetchUserAnalytics(client, user)
      )(user)
    ),
    TE.map(
      ({ user, analytics }: UserWithAnalyticsOptions): UserWithAnalytics => {
        return {
          user: O.toUndefined(user),
          analytics: O.toUndefined(analytics),
        };
      }
    ),
    TE.map(getAnalyticsUser),
    TE.getOrElse(() => {
      return T.of({
        user: undefined,
        analytics: undefined,
      } as UserWithAnalytics);
    })
  );
